<style>
    /* 视图全屏 */
    html,
    body,
    #app {
        min-width: 1280px;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif;
        font-size: 12px !important;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
</style>
<template>
    <div id="app">
        <!-- 嵌套路由 -->
        <router-view :key="router_view_key"/>
    </div>
</template>
<script>
    export default {
        name: "App",
        computed: {
            //使不同url路由到相同组件,也可重新渲染(key不同)
            router_view_key() {
                return this.$route.path;
            },
        },
    };
</script>


