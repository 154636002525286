import Vue from 'vue' //加载vue框架
import App from './App.vue' //SPA入口页面
import Vuex from 'vuex'
import router from './router' //加载VueRouter

Vue.config.productionTip = false

/** 引入第三方依赖包 */
import './plugins/element.js' // 引入element-ui
import './plugins/datav.js' //引入datav
import './plugins/antd.js' // 引入ant-design-vue
import "./plugins/iconfont/iconfont.css"; //引入阿里巴巴矢量图标库
import {MessageBox} from 'element-ui'

Vue.prototype.$confirm=MessageBox.confirm;

Vue.use(Vuex)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')