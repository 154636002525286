import Vue from 'vue'
import VueRouter from 'vue-router'
import request from "../plugins/axios.js";

Vue.use(VueRouter)

const routes = [{ //0.超级管理员
    path: '/admin',
    component: () => import('../views/layout.vue'),
    children: [
        {
            name: "/admin/index",
            path: "index",
            component: () => import('../views/admin/index.vue')
        },
        {
            name: "/admin/device",//租
            path: "device",
            component: () => import('../views/admin/device.vue')
        },
        {
            name: "/admin/user",
            path: "user",
            component: () => import('../views/admin/user.vue')
        },
        {
            name: "/admin/report",
            path: "report",
            component: () => import('../views/admin/report.vue')
        },
        {
            name: "/admin/system",
            path: "system",
            component: () => import('../views/admin/system.vue')
        },
        //新后台
        {
            name: "/admin/users",//用户管理
            path: "users",
            component: () => import('../views/admin/users.vue')
        },
        {
            name: "/admin/devices",//设备管理
            path: "devices",
            component: () => import('../views/admin/devices.vue')
        },
        {
            name: "/admin/data",//数据管理
            path: "data",
            component: () => import('../views/admin/data.vue')
        },
        {
            name: "/admin/indexs",
            path: "indexs",
            component: () => import('../views/admin/indexs.vue')
        },
        {
            name: "/admin/indexUser",
            path: "indexUser",
            component: () => import('../views/admin/indexUser.vue')
        },
        ,
        {
            name: "/admin/userData",
            path: "userData",
            component: () => import('../views/admin/userData.vue')
        }
    ]
},
    {
        path: "/*",
        component: () => import('../views/login.vue')
    },
]

const router = new VueRouter({
    mode: 'history', //去掉url中的#
    base: process.env.BASE_URL,
    routes
})

//全局前置路由守卫---初始化的时候被调用、每次路由切换的时候被调用
router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem("token");
    let role = window.localStorage.getItem("role");
    let company = window.localStorage.getItem("company");

    //即判断用户是否进入了需要鉴权的路由下
    console.log(to.path)
    if (to.path == '/') {
        next()
    } else {
        //如果不是，则直接放心即可
        // request({
        //   url: "/admin/Read/Auth",
        //   data: {
        //     role,
        //     url: to.path,
        //   },
        // }).then((ret) => {
        //   if (ret.code == 1) {
        next()
        //   }
        // });
    }
})

export default router